import { useRouterNavigate, useRouterQuery } from '@swe/shared/providers/router';
import { Hero } from '@swe/shared/ui-kit/components/hero';
import { EmailCodeIllustration } from '@swe/shared/ui-kit/components/illustration';

import { Routes } from 'common/router/constants';

const MarketingSubscriptionEmailCheck = () => {
  const query = useRouterQuery();
  const navigate = useRouterNavigate();

  return (
    <Hero
      illustration={<EmailCodeIllustration />}
      title="Please Check Your Email"
      content={
        <div>
          An email has been sent to {query.email} with a link to opt-in for our email updates. If you haven&apos;t
          received an email please check your spam folder.
        </div>
      }
      border={false}
      actions={[
        {
          children: 'Continue shopping',
          ariaLabel: 'Continue shopping',
          block: false,
          onClick: () => navigate(Routes.Home),
        },
      ]}
    />
  );
};

export { MarketingSubscriptionEmailCheck };
