import { ApplicationPage } from 'app/types';
import { MarketingSubscriptionEmailCheck } from 'domains/marketing/components/marketing-subscription-check-email';

const MarketingSubscriptionEmailCheckPage: ApplicationPage = () => {
  return <MarketingSubscriptionEmailCheck />;
};

MarketingSubscriptionEmailCheckPage.getMainLayoutProps = () => ({
  header: null,
  subheader: null,
  footer: null,
  centered: true,
  size: 'md',
});

export default MarketingSubscriptionEmailCheckPage;
